






























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Headline, IndexedHeadlines} from '@/model/article/article.model';
import PanelBox from '@/components/ui/PanelBox.vue';
import dayjs from 'dayjs';
import DateFilters from '@/mixins/date-filters.mixin';
import { RawLocation } from 'vue-router';

@Component({
  components: {PanelBox},
  mixins: [DateFilters],
})
export default class NewsHeadlines extends Vue {
  @Prop(Array) headlines!: Headline[];
  @Prop({default: true}) showDates!: boolean;
  @Prop({default: false}) smallDates!: boolean;

  link(headline: Headline): RawLocation {
    return {name: 'route-news-id', params: {id: headline.id.toString()}};
  }

  get sorted(): IndexedHeadlines[] {
    if (!this.headlines) {
      return [];
    }
    const result: any = [];
    this.headlines.forEach(headline => {
      const date = dayjs(headline.timestamp).format('DD. MMMM YYYY');
      if (!result.hasOwnProperty(date)) {
        result[date] = [];
      }
      result[date].push(headline);
    });
    return result;
  }
}

export type ArticleWeight = 1 | 2 | 3 | 4 | 5;
export type TopstoryType = 'news' | 'game' | 'article';


export interface Article extends Headline {
	id: number;
	/** @Deprectaed Prefer ISO formatted dates */
	dateStr: string;
	timestamp: string;
	weight?: ArticleWeight;
	topics?: string[];
	title: string;
	lead: string;
	body?: string | string[];
	author: string;
	authorDisplay?: string;
	pictureURL?: string;
	createdAt: Date;
	keywords?: Keyword[];
}

export interface BackgroundSideArticle {
	id: number;
	title: string;
	body: string;
}

export interface BackgroundArticle extends Article {
	updatedAt: Date;
	authorDesc: string;
	children: BackgroundSideArticle[];
}

/** ch.hockeyfans.core.news.KeywordValue.KeywordType */
export type KeywordType = 'PLAYER' | 'TEAM' | 'OTHER';
export const TOPIC = {
	nationalteams: 'Nationalteams'
};
export const PREFIX = {
	nhl: 'NHL',
	nati: 'Nati',
	yog: 'YOG',
	khl: 'KHL',
	ahl: 'AHL'
};
export const KEYWORD = {
	nla: 'nla',
	nlb: 'nlb',
	nhl: 'nhl',
	ahl: 'ahl',
	khl: 'khl',
	ama: 'ama',
	chl: 'chl',
	int: 'int'
};

export interface Keyword {
	value: string;
	resolved?: string;
	type: KeywordType;
	image: boolean;
}

export interface Topstory extends Article {
	header: string;
	image?: string;
	playerImage?: string;
	type: TopstoryType;
}

export interface GameArticle extends Article {
	gameId: string;
}

export interface Headline {
	id: number;
	title: string;
	timestamp: string;
	createdAt?: Date;
}

export interface IndexedArticles {
	key: string;
	articles: Article[];
}

export interface IndexedHeadlines {
	key: string;
	headlines: Headline[];
}

export interface IndexedKeywords {
	key: string;
	keywords: Keyword[];
}




































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Article, Keyword, TOPIC, PREFIX, KEYWORD} from '@/model/article/article.model';
import dayjs from 'dayjs';
import {Getter} from 'vuex-class';
import {NEWS_CONFIG} from '@/store/context.store';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import BTooltip from 'buefy/src/components/tooltip/Tooltip.vue';
import ShareThis from '@/components/shared/social/ShareThis.vue';
import {RawLocation} from 'vue-router';
import StringFilters from '@/mixins/string-filters.mixin';

@Component({
  components: {ShareThis, BTooltip, HfTeamLogo, PlayerPicture},
  mixins: [StringFilters]
})
export default class NewsHeadline extends Vue {

  @Prop(Object) article!: Article;
  @Prop(Boolean) showLead!: boolean;

  @Getter(NEWS_CONFIG) newsConfig!: any;

  get normalizedTitle() {
    let title = this.article.title;
    for (const [key, value] of Object.entries(PREFIX)) {
      title = title.replace(`${value}:`, '').trim();
    }
    return title;
  }

  get teamPictures() {
    if (!this.article || !this.article.keywords) {
      return [];
    }
    // special handling for FRA: Frauen vs. Frankreich
    return this.article.keywords
      .filter((k: Keyword) => k.type === 'TEAM' && k.value !== 'fra')
      .slice(0, this.newsConfig.maxTeamPictures);
  }

  get detailLink(): RawLocation {
    return {name: 'route-news-id', params: {id: this.article.id.toString()}};
  }

  get keywordsLimit(): Keyword[] {
    if (!this.article || !this.article.keywords) {
      return [];
    }
    return this.article.keywords.slice(0, this.newsConfig.maxKeywords);
  }

  get subtitle(): string[] {
    const createdAtDate = dayjs(this.article.timestamp).format('D. MMM. YYYY');
    const createdAtTime = dayjs(this.article.timestamp).format('HH:mm');
    return [`${createdAtDate} · ${createdAtTime}`];
  }

  get playerIdPicture() {
    if (!this.article || !this.article.keywords) {
      return null;
    }
    const playerId = this.article.keywords.find(k => k.type === 'PLAYER' && k.image);
    return playerId ? playerId.value : null;
  }

  get isNla(): boolean {
    return this.hasKeyword(KEYWORD.nla);
  }

  get isNlb(): boolean {
    return this.hasKeyword(KEYWORD.nlb);
  }

  get isNhl(): boolean {
    return this.hasKeyword(KEYWORD.nhl) || this.startsWith(PREFIX.nhl);
  }

  get isAhl(): boolean {
    return this.hasKeyword(KEYWORD.ahl) || this.startsWith(PREFIX.ahl);
  }

  get isAma(): boolean {
    return this.hasKeyword(KEYWORD.ama);
  }

  get isKhl(): boolean {
    return this.hasKeyword(KEYWORD.khl) || this.startsWith(PREFIX.nhl);
  }

  get isChl(): boolean {
    return this.hasKeyword(KEYWORD.chl);
  }

  get isInternational(): boolean {
    return this.hasKeyword(KEYWORD.int);
  }

  get isNati(): boolean {
    return this.startsWith(PREFIX.nhl) || this.hasTopic(TOPIC.nationalteams);
  }

  hasKeyword(keyword: string): boolean {
    if (!this.article || !this.article.keywords) {
      return false;
    }
    return this.article.keywords.find(k => k.value === keyword) !== undefined;
  }

  startsWith(prefix: string): boolean {
    return this.article.title.startsWith(prefix);
  }

  hasTopic(topic: string): boolean {
    return this.article.topics !== undefined && this.article.topics.find(t => t === topic) !== undefined;
  }

}

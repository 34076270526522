













import {Component, Prop, Vue} from 'vue-property-decorator';
import {Article} from '@/model/article/article.model';
import NewsArticle from '@/components/news/NewsArticle.vue';
import NewsHeadline from '@/components/news/NewsHeadline.vue';

@Component({
  components: {NewsHeadline, NewsArticle},
})
export default class NewsColumns extends Vue {
  @Prop(Array) articles!: Article[];
  @Prop(Boolean) showLead!: boolean;
}

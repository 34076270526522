






















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Article, Keyword} from '@/model/article/article.model';
import dayjs from 'dayjs';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import BTooltip from 'buefy/src/components/tooltip/Tooltip.vue';
import ShareThis from '@/components/shared/social/ShareThis.vue';
import {Severity} from '@sentry/types/dist/severity';
import * as Sentry from '@sentry/browser';
import { NEWS_CONFIG } from '@/store/context.store';
import { Getter } from 'vuex-class';

@Component({
  components: {ShareThis, BTooltip, HfTeamLogo, PlayerPicture},
})
export default class NewsArticle extends Vue {

  @Prop(Object) article!: Article;
  @Getter(NEWS_CONFIG) newsConfig!: any;

  get keywordsLimit(): Keyword[] {
    if (!this.article || !this.article.keywords) {
      return [];
    }
    return this.article.keywords.slice(0, this.newsConfig.maxKeywords);
  }

  get subtitle(): string[] {
    const createdAtDate = dayjs(this.article.timestamp).format('D. MMM. YYYY');
    const createdAtTime = dayjs(this.article.timestamp).format('HH:mm');
    const author = this.article.authorDisplay;
    if (!author) {
      const msg = `No author-display for '${this.article.author}' in newsId=${this.article.id}`;
      Sentry.captureMessage(msg, Severity.Warning);
      this.$log.warn(msg);
    }
    return [`${createdAtDate} · ${createdAtTime}`, `Von ${author}`];
  }

}
